import React from "react";
import Avantis from "../vendor/Avantis";

const VideoAd = () => {
  return (
    <div className="adunit adunit--video">
      <div className="adunit__container">
        <Avantis
          mobileId={process.env.GATSBY_AVANTIS_HOME_TAGID}
          desktopId={process.env.GATSBY_AVANTIS_HOME_TAGID}
        />
      </div>
    </div>
  );
};

export default VideoAd;
